<template>
  <q-page padding>
    <q-dialog v-model="showModal" persistent ref="dialogPassword">
      <q-card style="width: 800px; max-width: 80vw">
        <q-form>
          <q-toolbar class="bg-primary text-white">
            <q-toolbar-title> Campanhas existentes</q-toolbar-title>
          </q-toolbar>
          <q-card-section class="row q-col-gutter-md">
            <div class="col-12">
              <p class="text-red-5"><b>ATENÇÃO!</b> Já existe(m) campanha(s) cadastrada(s) para este anunciante.</p>
              <p class="text-red-5">
                Por favor, antes de continuar verifique na lista abaixo se a campanha já está cadastrada.
              </p>
            </div>
          </q-card-section>
          <q-card-section>
            <q-table
              :data="dataCampaignsByAdvertiser"
              flat
              bordered
              hide-no-data
              hide-bottom
              :pagination="{
                sortBy: 'name',
                rowsPerPage: 100
              }"
              :columns="[
                {
                  name: 'name',
                  align: 'left',
                  label: 'Nome',
                  field: 'name'
                },
                {
                  name: 'status',
                  align: 'left',
                  label: 'Status',
                  field: 'status'
                }
              ]"
            >
              <template v-slot:body-cell-status="props">
                <q-td :props="props">
                  <q-chip v-if="props.value === 0" text-color="white" color="warning">Pendente</q-chip>
                  <q-chip v-if="props.value === 1" text-color="white" color="primary">Ativado</q-chip>
                  <q-chip v-if="props.value === 2" text-color="white" color="accent">Pausado</q-chip>
                  <q-chip v-if="props.value === 3" text-color="white" color="negative">Desativado</q-chip>
                  <q-chip v-if="props.value === 4" text-color="white" color="purple">Em implementação</q-chip>
                </q-td>
              </template>
            </q-table>
          </q-card-section>
          <q-card-actions align="right">
            <q-btn no-caps class="q-px-md" unelevated label="Ciente" color="primary" v-close-popup />
          </q-card-actions>
        </q-form>
      </q-card>
    </q-dialog>

    <q-form @validation-success="advanceVerify" @submit="onSave">
      <q-toolbar class="column">
        <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">
          <!-- {{ campaignSelect ? $t('edit') : $t('registration_of') }}{{ $t("campaigns") }} -->
          {{ $t('campaigns_registration') }}
        </q-toolbar-title>
        <q-breadcrumbs class="q-mr-auto q-ma-sm">
          <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
          <q-breadcrumbs-el :label="$t('campaigns')" to="/campaigns" />
          <q-breadcrumbs-el :label="$t('campaigns_registration')" exact />
        </q-breadcrumbs>
      </q-toolbar>
      <q-separator spaced />
      <q-card flat class="q-mt-md">
        <q-card-section class="row items-center">
          <q-stepper
            v-model="step"
            ref="formCampaign"
            color="primary"
            animated
            :header-nav="!!campaignSelect"
            alternative-labels
            flat
            class="full-width"
          >
            <!-- FORM BEGINS -->

            <!-- STEP 1 -->
            <q-step name="step1" title="Etapa 1" icon="mdi-domain" :done="step > 1">
              <q-card flat>
                <q-separator />
                <q-card-section class="row q-col-gutter-md q-pa-none">
                  <div class="col-12 q-mt-md">
                    <q-checkbox left-label v-model="form.is_test" val="anunciante" label="Campanha Teste" />
                  </div>
                  <div class="col-6">
                    <select-request
                      :v-model="form.advertiser"
                      outlined
                      label="Advertiser*"
                      endpoint="/redirect?app=ADVERTISER&path=/api/get/advertiser"
                      option-label="trademark"
                      option-value="id"
                      clearable
                      @update="
                        (value) => {
                          form.advertiser = value;
                          checkExistCampaign(value.id);
                          form.advertiser = value;
                        }
                      "
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-6">
                    <select-request
                      :v-model="form.comercial_manager"
                      label="Gerente Comercial*"
                      outlined
                      endpoint="user/user-by-profile/2"
                      option-label="name"
                      option-value="id"
                      clearable
                      @update="
                        (value) => {
                          form.comercial_manager = value;
                        }
                      "
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-6">
                    <!-- <span class="text-weight-medium">Nome da Campanha*</span> -->
                    <q-input
                      outlined
                      v-model="form.name"
                      type="text"
                      label="Nome da Campanha*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-6">
                    <!-- <span class="text-weight-medium">Nome da Campanha*</span> -->
                    <q-input
                      outlined
                      v-model="form.campaign_description"
                      autogrow
                      label="Descrição da Campanha*"
                      lazy-rules
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-6">
                    <div class="row q-col-gutter-sm">
                      <div class="col-4">
                        <q-select
                          v-model="form.url.protocol"
                          :options="['https://', 'http://']"
                          label="Protocol URL*"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          :rules="[isRequired]"
                        />
                      </div>
                      <div class="col-8">
                        <q-input
                          outlined
                          type="text"
                          label="URL*"
                          v-model="form.url.link"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-3">
                    <select-request
                      :v-model="form.segmentation"
                      label="Segmentação"
                      outlined
                      endpoint="redirect?app=ADVERTISER&path=/api/get/campaign/segmentation"
                      option-label="description"
                      option-value="id"
                      clearable
                      @update="
                        (value) => {
                          form.segmentation = value === null ? null : value.id;
                        }
                      "
                      :rules="[isRequired]"
                    />
                  </div>

                  <div class="col-3">
                    <q-select
                      use-input
                      v-model="form.category_id"
                      @filter="filterCategories"
                      :options="categoriesFiltered"
                      option-label="name"
                      outlined
                      clear-icon="mdi-close"
                      clearable
                      option-value="id"
                      label="Categoria*"
                      lazy-rules
                      :rules="[(val) => !!val || 'Field is required']"
                    />
                  </div>

                  <div class="col-6">
                    <div class="row q-col-gutter-sm">
                      <div class="col-6">
                        <date-input
                          :v-model="form.activation_date"
                          outlined
                          label="Data início*"
                          :rules="[isRequired]"
                          @update="form.activation_date = $event"
                        />
                      </div>

                      <div class="col-6">
                        <date-input
                          :v-model="form.expiration_date"
                          outlined
                          label="Data fim"
                          @update="form.expiration_date = $event"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-6">
                    <div class="row q-col-gutter-sm">
                      <div class="col-4">
                        <q-select
                          v-model="form.budget_currency"
                          :options="currencyOptions"
                          label="Budget"
                          outlined
                          clearable
                          clear-icon="mdi-close"
                          emit-value
                          map-options
                        />
                      </div>
                      <div class="col-8">
                        <q-input outlined type="number" label="Valor Ilimitado" v-model="form.budget_amount" />
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <!-- <q-uploader
                      label="Logo"
                      color="primary"
                      flat
                      bordered
                      accept=".jpg,.jpeg,.png,.gif"
                      class="full-width"
                      max-files="1"
                      :factory="factoryFn"
                      ref="logoCampaign"
                    /> -->
                    <q-file
                      outlined
                      ref="images"
                      v-model="logo"
                      accept=".jpg,.jpeg,.png,.gif"
                      class="file-upload q-pt-md"
                      style="z-index: 1 !important"
                    >
                      <template v-slot:prepend v-if="logo == null">
                        <div class="full-width bg-transparent absolute-center text-center">
                          <div class="absolute text-body1" style="top: -30px; left: 10px">Logotipo</div>
                          <div class="text-h6 text-primary">Arraste e solte aqui!</div>
                          <div class="text-caption">...ou clique para selecionar os arquivos do computador</div>
                        </div>
                      </template>
                      <template v-slot:file="{ file }">
                        <div class="q-pa-md text-center">
                          <div class="absolute text-body1 text-grey-6" style="top: 5px; left: -5px">Logotipo</div>
                          <q-card flat bordered class="q-mt-sm">
                            <q-img :src="parceToUrl(file)" style="width: 200px; height: 200px" contain>
                              <div class="absolute-bottom text-caption text-center">
                                {{ file.name }} -
                                {{ formatBytes(file.size) }}
                              </div>
                            </q-img>
                            <q-btn
                              icon="mdi-close"
                              class="absolute text-white bg-negative"
                              style="top: 15px; right: 0px; transform: translateY(-50%)"
                              dense
                              unelevated
                              @click.prevent="logo = null"
                            />
                          </q-card>
                        </div>
                      </template>
                    </q-file>
                    <!-- <q-btn
                      color="primary"
                      flat
                      label="OK"
                      @click="uploadLogoCampaign(logo)"
                    /> -->
                  </div>

                  <!-- INPUT CPA CPC OU CPL -->
                  <div class="col-12">
                    <q-separator spaced />

                    <div class="row q-col-gutter-sm">
                      <span class="col-12 text-weight-medium">Modelo de Negociação*</span>
                      <div class="col-12">
                        <q-btn @click="addModeloNegociacao('cpa')" label="+ CPA" color="primary" />
                      </div>

                      <!-- CPA -->
                      <div
                        class="col-12 row q-col-gutter-sm"
                        v-for="comission in checkModeloNegociacao('cpa')"
                        :key="comission.id"
                      >
                        <div class="col-3">
                          <q-input
                            outlined
                            v-model="comission.category"
                            type="text"
                            dense
                            label="Nome*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-3">
                          <q-select
                            v-model="comission.format"
                            :options="[
                              { label: 'Valor', value: 'value' },
                              {
                                label: 'Porcentagem',
                                value: 'percentage'
                              }
                            ]"
                            label="Valor*"
                            outlined
                            dense
                            emit-value
                            clearable
                            clear-icon="mdi-close"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-3">
                          <q-select
                            v-model="comission.currency"
                            :options="currencyOptions"
                            emit-value
                            map-options
                            label="Moeda*"
                            outlined
                            dense
                            clearable
                            clear-icon="mdi-close"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-2">
                          <q-input
                            outlined
                            v-model="comission.value"
                            type="number"
                            dense
                            label="Valor*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-1">
                          <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close" />
                        </div>
                      </div>

                      <div class="col-12">
                        <q-btn @click="addModeloNegociacao('cpl')" label="+ CPL" color="primary" />
                      </div>

                      <!-- CPL -->
                      <div
                        class="col-12 row q-col-gutter-sm"
                        v-for="comission in checkModeloNegociacao('cpl')"
                        :key="comission.id"
                      >
                        <div class="col-3">
                          <q-input
                            outlined
                            v-model="comission.category"
                            type="text"
                            dense
                            label="Nome*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-3">
                          <q-select
                            v-model="comission.currency"
                            :options="currencyOptions"
                            emit-value
                            map-options
                            label="Moeda*"
                            outlined
                            dense
                            clearable
                            clear-icon="mdi-close"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-2">
                          <q-input
                            outlined
                            v-model="comission.value"
                            type="number"
                            dense
                            label="Valor*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-1">
                          <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close">
                            <q-tooltip> Remover contato</q-tooltip>
                          </q-btn>
                        </div>
                      </div>

                      <div class="col-12">
                        <q-btn
                          @click="addModeloNegociacao('cpc')"
                          label="+ CPC"
                          color="primary"
                          :disabled="!checkExistCPC('cpc')"
                        />
                      </div>

                      <!-- CPC -->
                      <div
                        class="col-12 row q-col-gutter-sm"
                        v-for="comission in checkModeloNegociacao('cpc')"
                        :key="comission.id"
                      >
                        <div class="col-3">
                          <q-input
                            outlined
                            v-model="comission.category"
                            type="text"
                            dense
                            label="Nome*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-3">
                          <q-select
                            v-model="comission.currency"
                            :options="currencyOptions"
                            emit-value
                            map-options
                            label="Moeda*"
                            outlined
                            dense
                            clearable
                            clear-icon="mdi-close"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-2">
                          <q-input
                            outlined
                            v-model="comission.value"
                            type="number"
                            dense
                            label="Valor*"
                            :rules="[isRequired]"
                          />
                        </div>

                        <div class="col-1">
                          <q-btn @click="deleteSpecificObjectInModel(comission.id)" color="negative" icon="mdi-close">
                            <q-tooltip> Remover contato</q-tooltip>
                          </q-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-step>

            <!-- STEP 2 -->
            <q-step name="step2" title="Etapa 2" icon="mdi-card-account-phone-outline">
              <q-card flat>
                <q-separator />
                <q-card-section class="row q-col-gutter-md q-pa-none q-pt-lg">
                  <div class="col-6">
                    <div class="row q-col-gutter-sm">
                      <div class="col-6">
                        <q-input
                          outlined
                          type="number"
                          label="Tempo de Cookie*"
                          v-model="form.cookie_time"
                          lazy-rules
                          :rules="[isRequired]"
                        />
                      </div>
                      <div class="col-6">
                        <q-select
                          v-model="form.cookie_time_metric"
                          :options="[
                            { label: 'Minutos', value: 'minutos' },
                            { label: 'Horas', value: 'horas' },
                            { label: 'Dias', value: 'dias' }
                          ]"
                          label="Periodo*"
                          outlined
                          emit-value
                          clearable
                          clear-icon="mdi-close"
                          :rules="[isRequired]"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Restrição*</span>
                        <div class="q-gutter-sm">
                          <q-checkbox
                            v-model="form.restriction_site_under"
                            val="1"
                            label="Site Under"
                            color="primary"
                            @input="(val) => uncheckRestrictionNone(val)"
                          />
                          <q-checkbox
                            v-model="form.restriction_word_purchase"
                            val="2"
                            label="Compra de Palavras"
                            color="primary"
                            @input="(val) => uncheckRestrictionNone(val)"
                          />
                          <q-checkbox
                            v-model="form.restriction_retargeting"
                            val="3"
                            label="Retargeting"
                            color="primary"
                            @input="(val) => uncheckRestrictionNone(val)"
                          />
                          <q-checkbox
                            v-model="form.restriction_email_marketing"
                            val="0"
                            label="E-mail Marketing"
                            color="primary"
                            @input="(val) => uncheckRestrictionNone(val)"
                          />
                          <q-checkbox
                            v-model="form.restriction_none"
                            label="Nenhum"
                            color="primary"
                            @input="(val) => unchekRestrictions(val)"
                          />
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Atribuição*</span>
                        <div class="q-gutter-sm">
                          <q-checkbox v-model="form.last_click" label="Last Click" color="primary" />
                          <q-checkbox v-model="form.assisted" label="Assistido" color="primary" />
                          <q-checkbox v-model="form.direct" label="Direto" color="primary" />
                          <q-checkbox v-model="form.organic" label="Orgânico" color="primary" />
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Mobile</span>
                        <div class="q-gutter-sm">
                          <q-checkbox v-model="form.mobile_responsive" label="Responsivo" color="primary" />
                          <q-checkbox v-model="form.mobile_msite" label="MStie" color="primary" />
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>

                  <div class="col-12">
                    <q-separator spaced />

                    <q-toolbar class="bg-white text-dark">
                      <q-toolbar-title class="text-body1"> Contato Comercial (cliente) </q-toolbar-title>

                      <q-btn
                        @click="
                          form.business_contact.push({
                            id: form.business_contact.length + 1,
                            name: null,
                            email: null,
                            phone: null,
                            appendParams: {}
                          })
                        "
                        color="primary"
                        icon="mdi-plus"
                        no-caps
                        unelevated
                      >
                        <q-tooltip> Adicionar novo contato</q-tooltip>
                      </q-btn>
                    </q-toolbar>
                  </div>

                  <div
                    class="col-12 row q-col-gutter-sm"
                    v-for="(contact, index) in form.business_contact"
                    :key="contact.id"
                  >
                    <div class="col-4">
                      <q-input
                        outlined
                        type="text"
                        label="Nome*"
                        dense
                        v-model="contact.name"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-4">
                      <q-input
                        outlined
                        type="email"
                        label="Email*"
                        dense
                        v-model="contact.email"
                        lazy-rules
                        :rules="[isRequired, isEmail]"
                      />
                    </div>

                    <div class="col-4">
                      <q-input
                        outlined
                        type="text"
                        label="Telefone*"
                        dense
                        v-model="contact.phone"
                        mask="+## ## #####-####"
                        unmasked-value
                        hint="Ex. +55 21 99999-9999"
                        lazy-rules
                        :rules="[isRequired]"
                      >
                        <template v-slot:after>
                          <q-btn
                            v-if="index != 0"
                            @click="form.business_contact.splice(index, 1)"
                            color="negative"
                            icon="mdi-close"
                            unelevated
                            no-caps
                          >
                            <q-tooltip> Remover contato</q-tooltip>
                          </q-btn>
                        </template>
                      </q-input>
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-step>

            <!-- STEP 3 -->
            <q-step name="step3" title="Etapa 3" icon="assignment">
              <q-card flat>
                <q-card-section>
                  <q-separator />

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Tipo de Integração*</span>
                        <div class="q-gutter-sm">
                          <q-radio v-model="form.integration_type" val="Pixel" label="Pixel" color="primary" />
                          <q-radio v-model="form.integration_type" val="Postback" label="Postback" color="primary" />
                          <q-radio v-model="form.integration_type" val="API" label="API" color="primary" />
                          <q-radio v-model="form.integration_type" val="Mobile" label="Mobile" color="primary" />
                          <q-radio v-model="form.integration_type" val="Outro" label="Outro" color="primary" />
                          <q-input
                            v-if="form.integration_type === 'Outro'"
                            v-model="otherIntegrationType"
                            label="Adicionar Informações"
                            outlined
                            dense
                          />
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>
                  <br />

                  <div class="col-12 q-gutter-sm">
                    <div class="row q-col-gutter-md">
                      <div class="col-6">
                        <q-card flat bordered>
                          <q-card-section>
                            <span class="text-weight-medium">Mastertag*</span>
                            <div class="q-gutter-sm">
                              <p class="text">Teremos implementação de script no site do Anunciante?</p>
                              <q-select
                                :options="['Sim', 'Não', 'Não sei']"
                                outlined
                                dense
                                clearable
                                clear-icon="mdi-close"
                                v-model="form.mastertag"
                                :rules="[isRequired]"
                              />
                            </div>
                          </q-card-section>
                        </q-card>
                      </div>
                      <div class="col-6">
                        <q-card flat bordered>
                          <q-card-section>
                            <span class="text-weight-medium">Liberação de GTM*</span>
                            <div class="q-gutter-sm">
                              <p class="text">
                                O time Afilio irá fazer a implementação, ou o cliente realizará sozinho?
                              </p>
                              <q-select
                                :options="['Time Afilio', 'Cliente', 'Não sei']"
                                outlined
                                dense
                                clearable
                                clear-icon="mdi-close"
                                v-model="form.gtm_release"
                                :rules="[isRequired]"
                              />
                            </div>
                          </q-card-section>
                        </q-card>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div class="row q-col-gutter-md">
                    <div class="col-12">
                      <q-input
                        outlined
                        v-model="form.parameterized_url"
                        autogrow
                        label="URL Parametrizada"
                        hint="Link da campanha já com os parâmetros de identificação de origem/mídia"
                        :rules="[isValidUrl]"
                      />
                    </div>
                  </div>
                  <br />

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Método de teste*</span>
                        <div class="q-gutter-sm">
                          <p class="text">
                            Precisaremos testar a campanha durante e após a implementação. Quais são as dificuldades que
                            podemos encontrar?
                          </p>
                          <div class="row q-col-gutter-md">
                            <div class="col-4">
                              <q-select
                                v-model="form.test_method_real_data_registration"
                                :options="['Sim', 'Não', 'Não sei']"
                                label="Cadastro com dados reais*"
                                outlined
                                dense
                                clearable
                                clear-icon="mdi-close"
                                :rules="[isRequired]"
                              />
                            </div>

                            <div class="col-4">
                              <q-select
                                v-model="form.test_method_no_invoice_purchase"
                                :options="['Sim', 'Não', 'Não sei']"
                                label="Compra que não tem boleto?*"
                                outlined
                                dense
                                clearable
                                clear-icon="mdi-close"
                                :rules="[isRequired]"
                              />
                            </div>

                            <div class="col-4">
                              <q-input outlined dense v-model="form.test_method_other_issues" autogrow label="Outros" />
                            </div>
                          </div>
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>
                  <br />

                  <div class="col-6 q-gutter-sm">
                    <q-card flat bordered>
                      <q-card-section>
                        <span class="text-weight-medium">Método de comprovação*</span>
                        <div class="q-gutter-sm">
                          <p class="text">
                            Como o cliente vai validar a conversão? Relatório do GA4 com ID de Conversão + Origem/Mídia
                            é o padrão, mas ele pode usar outra ferramenta.
                          </p>
                          <div class="row q-col-gutter-md">
                            <div class="col-12">
                              <q-input
                                outlined
                                dense
                                v-model="form.proof_method"
                                autogrow
                                label="Adicionar informações"
                                :rules="[isRequired]"
                              />
                            </div>
                          </div>
                        </div>
                      </q-card-section>
                    </q-card>
                  </div>

                  <q-separator spaced="xl" />

                  <q-toolbar class="bg-white text-dark">
                    <q-toolbar-title class="text-body1"> Contato para Implementação (Envio do pixel) </q-toolbar-title>

                    <q-btn
                      @click="
                        form.pixel_contacts.push({
                          id: form.pixel_contacts.length + 1,
                          name: null,
                          email: null,
                          phone: null,
                          appendParams: {}
                        })
                      "
                      color="primary"
                      icon="mdi-plus"
                      no-caps
                      unelevated
                    >
                      <q-tooltip> Adicionar novo contato</q-tooltip>
                    </q-btn>
                  </q-toolbar>

                  <div
                    class="row q-col-gutter-sm q-pa-none"
                    v-for="(contact, index) in form.pixel_contacts"
                    :key="contact.id"
                  >
                    <div class="col-4">
                      <q-input
                        outlined
                        type="text"
                        label="Nome*"
                        dense
                        v-model="contact.name"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-4">
                      <q-input
                        outlined
                        type="email"
                        label="Email*"
                        dense
                        v-model="contact.email"
                        lazy-rules
                        :rules="[isRequired, isEmail]"
                      />
                    </div>

                    <div class="col-3">
                      <q-input
                        outlined
                        type="text"
                        label="Telefone*"
                        dense
                        v-model="contact.phone"
                        mask="+## ## #####-####"
                        unmasked-value
                        hint="Ex. +55 21 99999-9999"
                        lazy-rules
                        :rules="[isRequired]"
                      />
                    </div>
                    <div class="col-1">
                      <q-btn
                        v-if="index != 0"
                        @click="form.pixel_contacts.splice(index, 1)"
                        color="negative"
                        icon="mdi-close"
                      />
                    </div>
                  </div>

                  <q-separator spaced="xl" />

                  <div class="row q-col-gutter-md">
                    <div class="col-6">
                      <q-select
                        v-model="form.contract"
                        :options="['Sim', 'Não']"
                        label="Envio do Contrato*"
                        outlined
                        clearable
                        clear-icon="mdi-close"
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-6">
                      <q-select
                        v-model="form.pi"
                        :options="['Sim', 'Não']"
                        label="Envio do PI*"
                        outlined
                        clearable
                        clear-icon="mdi-close"
                        :rules="[isRequired]"
                      />
                    </div>

                    <div class="col-12">
                      <q-input
                        outlined
                        v-model="form.additional_informations"
                        autogrow
                        label="Informações adicionais da Campanha"
                      />
                    </div>
                  </div>
                </q-card-section>
              </q-card>
            </q-step>
          </q-stepper>
        </q-card-section>
        <q-separator inset />
        <q-card-actions align="right" class="q-pa-md">
          <q-btn outline label="Cancelar" no-caps padding="sm lg" color="primary" @click="onClose" v-if="!id" />
          <q-btn
            outline
            v-if="step !== 'step1'"
            label="Voltar"
            no-caps
            padding="sm lg"
            color="primary"
            @click="$refs.formCampaign.previous()"
          />
          <q-btn
            unelevated
            color="primary"
            label="Avançar"
            v-if="step !== 'step3'"
            no-caps
            padding="sm lg"
            type="submit"
          />
          <q-btn
            v-if="step === 'step3'"
            unelevated
            label="Salvar"
            no-caps
            padding="sm lg"
            color="primary"
            type="submit"
          />
        </q-card-actions>
      </q-card>

      <!-- </q-card> -->
    </q-form>
  </q-page>
  <!-- </q-dialog> -->
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import AdvertiserService from '../../services/AdvertiserService';
import ConversorPhpService from '../../services/ConversorPhpService';

export default {
  components: {
    SelectRequest,
    DateInput
  },

  name: 'FormCampaign',

  mixins: [CampaignMixin, AdvertiserService, ConversorPhpService],

  props: {
    id: {
      type: [String, Number]
    }
  },

  data() {
    return {
      showModal: false,
      dataCampaignsByAdvertiser: [],
      categories: [],
      categoriesFiltered: [],
      tab: 'detalhamento',
      logo: null,
      step: 'step1',
      isento: false,
      otherIntegrationType: null,
      form: {
        is_test: false,
        advertiser: null,
        comercial_manager: null,
        name: null,
        campaign_description: null,
        url: {
          protocol: null,
          link: null
        },
        segmentation: null,
        category_id: null,
        activation_date: null,
        expiration_date: null,
        budget_currency: null,
        budget_amount: null,
        cookie_time: null,
        cookie_time_metric: null,

        // atribuicao:
        last_click: false,
        assisted: false,
        direct: false,
        organic: false,

        // mobile:
        mobile_responsive: false,
        mobile_msite: false,

        // restricoes:
        restriction_site_under: false,
        restriction_word_purchase: false,
        restriction_retargeting: false,
        restriction_email_marketing: false,
        restriction_none: false,

        contract: null,
        pi: null,
        business_contact: [{ id: 1, name: null, email: null, phone: null, appendParams: {} }],
        pixel_contacts: [{ id: 1, name: null, email: null, phone: null, appendParams: {} }],
        comissions: [],
        additional_informations: null,

        //Campos informacionais
        integration_type: null,
        mastertag: null,
        gtm_release: null,
        parameterized_url: '',
        test_method_real_data_registration: null,
        test_method_no_invoice_purchase: null,
        test_method_other_issues: null,
        proof_method: null
      },
      indexComissionsArray: 0
    };
  },

  created() {
    this.getCategories();
  },

  methods: {
    isValidUrl(val) {
      /* eslint-disable */
      const regex =
        /((https?|ftp)\:\/\/)?([a-z0-9+!*(),;?&=\$_.-]+(\:[a-z0-9+!*(),;?&=\$_.-]+)?@)?([:\/\a-z0-9-.]*)\.([a-z]{2,5})(\:[0-9]{2,5})?(\/([a-z0-9+\$_-]\.?)+)*\/?(\?[a-z+&\$_.-][a-z0-9!;:@&%=+\/\$_.-\|-]*)?(#[a-z_.-][a-z0-9+\$_.-]*)?/gm;
      /* eslint-enable */

      // if (!regex.test(val)) {
      //   return 'Url é inválida!';
      // }
      // return true;

      return regex.test(val) || val == '' || val == null || 'URL é inválida!';
    },

    async getCategories() {
      try {
        const { data, status } = await this.listCampaignsCategories();
        if (status === 200) {
          data.sort((a, b) => a.name.localeCompare(b.name));
          this.categories = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      }
    },

    filterCategories(val, update) {
      update(this.defaultFilter(val, 'categoriesFiltered', this.categories, 'name'));
    },

    unchekRestrictions(val) {
      if (val) {
        this.form.restriction_site_under = false;
        this.form.restriction_word_purchase = false;
        this.form.restriction_retargeting = false;
        this.form.restriction_email_marketing = false;
      }
    },

    uncheckRestrictionNone(val) {
      if (val) {
        this.form.restriction_none = false;
      }
    },

    async checkExistCampaign(id) {
      if (this.form.advertiser?.id != id) {
        this.dataCampaignsByAdvertiser = [];
        try {
          this.onLoading(true);
          const { data, status } = await this.searchCampaign('page=1', 'perPage=100', `advertiserId=${id}`);
          if (status === 200 && data.data.length > 0) {
            this.dataCampaignsByAdvertiser = data.data;
            this.showModal = true;
          }
        } catch (error) {
          if (error.response && error.response.status === 500)
            this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
          else this.errorNotify(error);
        } finally {
          this.onLoading(false);
        }
      }
    },

    deleteSpecificObjectInModel(id) {
      var indexComission = this.form.comissions.findIndex((ele) => {
        return ele.id === id;
      });

      if (indexComission !== -1) this.form.comissions.splice(indexComission, 1);
    },

    checkModeloNegociacao(modelo) {
      let comissions = this.form.comissions.filter((comission) => comission.type === modelo);
      if (modelo) return comissions;
    },

    checkExistCPC(modelo) {
      if (this.form.comissions.some((comission) => comission.type == modelo)) {
        return false;
      }
      return true;
    },

    addModeloNegociacao(modelo) {
      if (modelo == 'cpc') {
        if (!this.checkExistCPC(modelo)) return false;
      }

      this.indexComissionsArray++;

      switch (modelo) {
        case 'cpa':
          this.form.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpa',
            category: null,
            format: null,
            currency: null,
            value: null
          });
          break;

        case 'cpl':
          this.form.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpl',
            category: null,
            format: 'value',
            currency: null,
            value: null
          });
          break;

        case 'cpc':
          this.form.comissions.push({
            id: this.indexComissionsArray,
            type: 'cpc',
            category: null,
            format: 'value',
            currency: null,
            value: null
          });
          break;

        default:
          break;
      }
    },

    onClose() {
      // this.ActionSetDialogCampaign(false);
      this.$router.push({ name: 'Campaigns' });
    },

    async uploadLogoCampaign(image) {
      try {
        this.onLoading(true);

        let form = new FormData();
        form.append('image', image);
        form.append('bucket', 's3AdvertiserLogos');

        const { data, status } = await this.$http.post('/v2/upload/campaign-logo', form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (status === 200) return data.image_upload.image_url;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    advanceVerify() {
      if (this.form.comissions.length == 0) this.errorNotify('Selecione um Modelo de Negociação');
      else if (
        this.step == 'step2' &&
        !this.form.restriction_site_under &&
        !this.form.restriction_word_purchase &&
        !this.form.restriction_retargeting &&
        !this.form.restriction_email_marketing &&
        !this.form.restriction_none
      )
        this.errorNotify('Selecione pelo menos uma Restrição');
      else if (
        this.step == 'step2' &&
        !this.form.last_click &&
        !this.form.assisted &&
        !this.form.direct &&
        !this.form.organic
      )
        this.errorNotify('Selecione pelo menos uma Atribuição');
      else this.$refs.formCampaign.next();
    },

    async onSave() {
      if (this.step == 'step3' && this.form.pi != null && this.form.pixel_contacts[0].phone != null) {
        if (!this.form.integration_type) {
          this.errorNotify('Por favor, selecione o tipo de integração.');
          return;
        }

        var logo_url = null;
        if (this.logo) {
          logo_url = await this.uploadLogoCampaign(this.logo);
        }
        let formData = this.form;
        let commercialManagerId = formData.comercial_manager.user_id;
        let commercialManagerName = formData.comercial_manager.name;
        // let budget_currency = formData.budget_currency.value;

        let integrationType = this.form.integration_type;
        if (integrationType === 'Outro') {
          integrationType = this.otherIntegrationType;
        }

        let data = {
          ...formData,
          url: `${this.form.url.protocol}${this.form.url.link}`,
          activation_date: this.form.activation_date.replace(/-/g, '/'),
          expiration_date: this.form.expiration_date ? this.form.expiration_date.replace(/-/g, '/') : '',
          logo_url: logo_url,
          advertiser_id: this.form.advertiser.id,
          category_id: this.form.category_id.id,
          comercial_manager_id: commercialManagerId,
          comercial_manager_name: commercialManagerName,
          // budget_currency: budget_currency,
          pixel_contacts: JSON.stringify(formData.pixel_contacts),
          business_contact: JSON.stringify(formData.business_contact),
          comissions: JSON.stringify(formData.comissions),
          //Campos informacionais
          integration_type: integrationType,
          mastertag: formData.mastertag,
          gtm_release: formData.gtm_release,
          parameterized_url: formData.parameterized_url,
          test_method_real_data_registration: formData.test_method_real_data_registration,
          test_method_no_invoice_purchase: formData.test_method_no_invoice_purchase,
          test_method_other_issues: formData.test_method_other_issues,
          proof_method: formData.proof_method
        };

        delete data.advertiser;
        delete data.comercial_manager;

        this.createCampaign(data);
      }
    },

    async createCampaign(dataCreateCampaign) {
      this.onLoading(true);
      try {
        const { status } = await this.createCampaignService(dataCreateCampaign);

        if (status === 200 || status === 201) {
          await this.$router.push('/campaigns');
          this.successNotify('Campanha criada com sucesso!');
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async updateCampaign(id) {
      this.onLoading(true);
      try {
        const { status } = await this.$http.put('users/edit/' + id, this.parseQs(this.form));
        if (status === 200 || status === 201) {
          this.getCampaigns();
          this.ActionSetDialogCampaign(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style>
.file-upload .q-field__control,
.file-upload {
  min-height: 128px !important;
}
</style>
